const storedTheme = window.localStorage.getItem("theme");

function getPreferredTheme() {
  if (storedTheme) {
    return storedTheme;
  }

  return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
}

function setTheme(theme) {
  if (theme === "auto" && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    document.documentElement.setAttribute("data-bs-theme", "dark");
  } else {
    document.documentElement.setAttribute("data-bs-theme", theme);
  }
}

function showActiveTheme(theme) {
  const activeThemeIcon = document.querySelector("#cms-theme i");
  const btnToActive = document.querySelector(`[data-cms-theme="${theme}"]`);
  if (btnToActive === null) {
    return;
  }

  const iconOfActiveBtn = btnToActive.dataset.cmsThemeIcon;

  document.querySelectorAll("[data-cms-theme]").forEach((element) => {
    element.classList.remove("active");
  });

  btnToActive.classList.add("active");
  activeThemeIcon.setAttribute("class", iconOfActiveBtn);
}

setTheme(getPreferredTheme());

window.addEventListener("DOMContentLoaded", () => {
  showActiveTheme(getPreferredTheme());

  document.querySelectorAll("[data-cms-theme]").forEach((toggle) => {
    toggle.addEventListener("click", () => {
      const theme = toggle.getAttribute("data-cms-theme");
      localStorage.setItem("theme", theme);
      setTheme(theme);
      showActiveTheme(theme);
    });
  });
});
